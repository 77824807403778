// グローバルに使いたいモジュール,関数はこちらで定義
// 残りはcomponents配下に配置してバンドルする方向性

$(function () {
	$(document).on('click', '.activity__item a', function () {
		$($(this).attr('href')).addClass('is-active');
		return false;
	})
	$(document).on('click', '.close__btn a,.activity__popup__close', function () {
		$('.activity__popup').removeClass('is-active');
		return false;
	})


	$(document).on('click', '#nav .navopen', function () {
		$(".navopen").toggleClass("is-active");
		$(".nav ul").toggleClass("is-active");
	})
})
